import { TAG } from "../../components/generic/Tag/types";

export type NavLink = {
    url: string;
    name: string;
    tagVariant?: TAG;
    external?: boolean;
};
/**
 *  Decouple from mobile inplementation for now. We are updating the desktop view to use icons
 *  but leaving the mobile alone
 * */

// Admins get additional links in the settings menu in addition to the user ones
export const DesktopAdminSettingsNavList = [
    {
        url: "/DeviceDebugger",
        name: "Device Debugger",
    },
    {
        url: "/UserManager",
        name: "User Manager",
    },
    {
        url: "/AdminUserManager",
        name: "Admin User Manager",
    },
];

export const DesktopUserSettingsNavList = [
    {
        url: "/settings",
        name: "Settings",
    },
    {
        url: "/export",
        name: "Data Export",
    },
    {
        url: "https://grow-with-verdi.notion.site",
        name: "Product Updates",
        external: true,
    },
];

export const AdminToolsNavList = [
    {
        url: "/DeviceDebugger",
        name: "Device Debugger",
    },
    {
        url: "/UserManager",
        name: "User Manager",
    },
    {
        url: "/export",
        name: "Data Export",
    },
    {
        url: "/AdminUserManager",
        name: "Admin User Manager",
    },
];
